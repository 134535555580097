<template>
<div>
    <v-navigation-drawer v-model="drawer" :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'" app right width="300">
        <div class="text-center ">
            <v-avatar tile size='200'>
                <v-img contain :src="$config.logo"></v-img>
            </v-avatar>
            <!-- <div style="font-weight: bold">
                <h4 class="mt-3">وزارة الداخلية</h4>
                <h4> مـديرية شــؤون الرعـايـة الصحية</h4>
                <h4> لــقـــوى الامــــن الــــداخـــلـــي</h4>
            </div>
            <v-divider vertical="" inset="" class="mx-2" /> -->
        </div>

        <v-divider />

        <v-row class="ma-2" align="center">
            <!-- <v-col cols="3">
              <v-avatar size="60" rounded>
                <v-img
                  :src=" 
                    $store.state.user.userData.imageUrl
                      ? $service.url + '/' + $store.state.user.userData.imageUrl
                      : this.$store.state.defaultPhoto
                  "
                />
              </v-avatar>
            </v-col>
            <v-col>
              <h4 class=" mb-1">{{ $store.state.user.userData.fullName }}</h4>
              <v-chip label dense outlined color="#0080FF">
                {{ $store.state.user.userData.medicalCenter != null ? $store.state.user.userData.medicalCenter.name : "Admin" }}
              </v-chip>
            </v-col> -->
        </v-row>
        <!-- <v-divider /> -->

        <tree />

        <!-- <v-divider />
        <template>
            <div class="pa-2 text-center">
                <v-avatar size="100">
                    <v-img src="@/assets/images/logo.svg"/>
                </v-avatar><br/>
                <span style="color: #7c7c7c">
                    تم التطوير بواسطة<br/>
                    <a href="https://google.co/">شركة السحابة الذكية لتكنولوجيا المعلومات</a>
                    | {{ new Date().getFullYear() }}
                </span>
            </div>
        </template> -->
    </v-navigation-drawer>
</div>
</template>

<script>
export default {
    components: {
        tree: () => import('./Tree.vue'),
        // profile: () => import('./Profile'),
    },
    data() {
        return {
            loading: false
        }
    },
    props: {
        drawer: {
            type: Boolean,
            default: false, // Set your default value here
        },
    },
    methods: {
        logout() {
            this.loading = true
            this.$http.delete('logout').finally(() => {
                this.loading = false
                localStorage.removeItem("token");
                this.$router.push('/login');
            })

        },
    },

    created() {

    },

}
</script>
